.popular {
    overflow: hidden;
    padding-bottom: 140px;
    @include x {
        padding-bottom: 112px; }
    @include m {
        padding-bottom: 64px; }
    &__top {
        display: flex;
        align-items: center;
        margin-bottom: 54px;
        @include m {
            display: block;
            margin-bottom: 32px; } }
    &__stage {
        @include body-bold-1;
        color: $neutrals4; }
    .field {
        .select {
            min-width: 256px;
            @include m {
                min-width: 100%; }
            @include dark {
                background: none; } } }
    &__box {
        margin-right: auto;
        @include m {
            margin: 0 0 16px; }
        .select-empty {
            width: auto;
            min-width: 240px;
            margin-left: -24px;
            @include m {
                display: inline-block; } } }
    &__wrapper {
        margin: 0 -16px;
        @include m {
            margin: 0 -4px; } }
    &__item {
        padding: 24px;
        border-radius: 16px;
        background: $neutrals8;
        transition: box-shadow .2s;
        @include dark {
            background: $neutrals1; }
        &:hover {
            box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
            .popular__avatar {
                width: 80px;
                @include d {
                    width: 64px; } } } }
    &__head {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $neutrals6;
        @include dark {
            border-color: $neutrals3; } }
    &__rating {
        display: flex;
        align-items: center;
        height: 24px;
        margin-right: auto;
        padding: 0 8px;
        border-radius: 12px;
        @include caption-bold-2;
        color: $neutrals8; }
    &__icon {
        margin-right: 4px;
        img {
            width: 16px; } }
    &__control {
        display: flex; }
    &__button {
        .icon {
            width: 24px;
            height: 24px;
            fill: $neutrals5;
            transition: fill .2s; }
        &:hover {
            .icon {
                fill: $blue; } }
        &:not(:last-child) {
            margin-right: 8px; } }
    &__add {
        position: relative;
        .icon {
            transition: all .2s;
            &:nth-child(2) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                opacity: 0; } }
        &.active {
            .icon {
                &:first-child {
                    opacity: 0; }
                &:nth-child(2) {
                    opacity: 1; } } } }
    &__body {
        text-align: center; }
    &__avatar {
        position: relative;
        width: 64px;
        margin: 0 auto 16px;
        transition: width .2s;
        &:before {
            content: "";
            display: block;
            padding-bottom: 100%; } }
    &__preview {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(1.05); } }
    &__reward {
        position: absolute;
        right: -4px;
        bottom: -4px; }
    &__name {
        margin-bottom: 2px;
        font-weight: 500; }
    &__price {
        @include caption-2;
        color: $neutrals4;
        span {
            font-weight: 600;
            color: $neutrals2;
            @include dark {
                color: $neutrals6; } } }
    &__slider {
        visibility: hidden;
        @include t {
            padding-bottom: 80px; }
        @include m {
            padding-bottom: 72px; }
        &.slick-initialized {
            visibility: visible; } }
    .slick-list {
        overflow: visible;
        height: 231px;
        @include s {
            margin: 0 -136px 0 0; } }
    .slick-track {
        display: flex; }
    .slick-slide {
        height: auto;
        padding: 0 16px;
        opacity: 0;
        transition: opacity .4s;
        @include m {
            padding: 0 4px; }
        &.slick-active {
            opacity: 1; } }
    .slick-arrow {
        top: 111px;
        @include t {
            top: auto;
            bottom: 0; } }
    .slick-prev {
        left: -60px;
        @include x {
            left: -40px; }
        @include t {
            left: calc(50% - 56px); }
        @include m {
            left: calc(50% - 44px); } }
    .slick-next {
        right: -60px;
        @include x {
            right: -40px; }
        @include t {
            right: calc(50% - 56px); }
        @include m {
            right: calc(50% - 44px); } } }
