.profile {
    &__head {
        position: relative;
        display: flex;
        align-items: flex-end;
        height: 326px;
        padding: 32px 0;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        overflow: hidden;
        @include t {
            height: 260px;
            padding-bottom: 64px; }
        @include m {
            height: 230px;
            padding-bottom: 56px; }
        .container {
            display: flex;
            justify-content: flex-end; }
        &.active {
            .profile__btns {
                opacity: 0; }
            .profile__file {
                opacity: 1;
                visibility: visible; } } }
    &__btns {
        display: flex;
        justify-content: flex-end;
        transition: opacity .2s;
        .profile__button {
            box-shadow: 0 0 0 2px $neutrals4 inset;
            color: $neutrals8;
            @include m {
                flex-grow: 1;
                padding: 0 10px; }
            .icon {
                width: 16px;
                height: 16px;
                fill: $neutrals8; }
            &:hover {
                box-shadow: 0 0 0 2px $blue inset; }
            @include dark {
                box-shadow: 0 0 0 2px $neutrals4 inset;
                &:hover {
                    box-shadow: 0 0 0 2px $blue inset; } }
            &:not(:last-child) {
                margin-right: 16px; } } }
    &__file {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $neutrals8;
        opacity: 0;
        visibility: hidden;
        transition: all .2s;
        @include m {
            align-items: flex-start;
            padding-top: 40px; }
        &:before,
        &:after {
            content: "";
            position: absolute; }
        &:before {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($neutrals1, .6); }
        &:after {
            top: 8px;
            left: 8px;
            right: 8px;
            bottom: 8px;
            border: 2px dashed $neutrals6;
            border-radius: 12px; }
        input {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            font-size: 1400px;
            opacity: 0; }
        .icon {
            margin-bottom: 24px;
            width: 48px;
            height: 48px;
            fill: $neutrals8;
            @include m {
                width: 24px;
                height: 24px;
                margin-bottom: 12px; } }
        .profile__button {
            position: absolute;
            right: 16px;
            bottom: 16px;
            z-index: 4;
            @include t {
                bottom: 48px; }
            @include m {
                right: 50%;
                transform: translateX(50%); } } }
    &__wrap {
        position: relative;
        z-index: 2; }
    &__info {
        font-size: 24px;
        line-height: (32/24);
        font-weight: 600;
        @include m {
            font-size: 16px; } }
    &__text {
        @include body-2;
        @include m {
            font-size: 12px; } }
    &__body {
        position: relative;
        z-index: 3;
        padding: 80px 0;
        @include t {
            padding-top: 0; }
        @include m {
            padding: 0 0 64px; }
        .profile__center {
            display: flex;
            align-items: flex-start;
            @include t {
                display: block; } } }
    .user {
        flex-shrink: 0;
        width: 256px;
        margin-top: -193px;
        @include t {
            width: 100%;
            margin: -32px 0 48px; } }
    &__wrapper {
        flex: 0 0 calc(100% - 256px);
        width: calc(100% - 256px);
        padding-left: 64px;
        @include x {
            padding-left: 32px; }
        @include t {
            width: 100%;
            padding-left: 0; } }
    &__nav {
        display: flex;
        margin: 0 -6px 32px;
        @include m {
            margin: 0 -32px 32px;
            overflow: auto;
            overflow-x: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            -webkit-overflow-scrolling: touch;
            &::-webkit-scrollbar {
                display: none; }
            &:before,
            &:after {
                content: "";
                flex-shrink: 0;
                width: 32px;
                height: 1px; } } }
    &__link {
        flex-shrink: 0;
        margin: 0 6px;
        padding: 6px 12px;
        border-radius: 14px;
        background: none;
        @include button-2;
        color: $neutrals4;
        transition: all .2s;
        @include m {
            margin: 0; }
        &:hover {
            color: $neutrals3;
            @include dark {
                color: $neutrals6; } }
        &.active {
            background: $neutrals3;
            color: $neutrals8;
            @include dark {
                background: $neutrals8;
                color: $neutrals2; } }
        &:not(:last-child) {
            @include m {
                margin-right: 12px; } } }
    &__item {
        display: none; }
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: -32px -16px 0;
        @include x {
            margin: -32px -10px 0; }
        @include d {
            margin: -32px -16px 0; }
        @include m {
            display: block;
            margin: 0; } }
    .card {
        flex: 0 0 calc(33.333% - 32px);
        width: calc(33.333% - 32px);
        margin: 32px 16px 0;
        @include x {
            flex: 0 0 calc(33.333% - 20px);
            width: calc(33.333% - 20px);
            margin: 32px 10px 0; }
        @include d {
            flex: 0 0 calc(50% - 32px);
            width: calc(50% - 32px);
            margin: 32px 16px 0; }
        @include m {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 32px; } } }
    .loader {
        margin: 32px auto 10px; }
    .followers {
        padding-top: 16px;
        @include m {
            padding-top: 0; } } }
