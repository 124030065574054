.followers {
    &__item {
        display: flex;
        padding-bottom: 32px;
        border-bottom: 1px solid $neutrals6;
        @include m {
            position: relative;
            display: block;
            padding: 0 100px 0 0;
            border: none; }
        @include dark {
            border-color: $neutrals3; }
        &:not(:last-child) {
            margin-bottom: 48px;
            @include m {
                margin-bottom: 32px; } } }
    &__user {
        display: flex;
        align-items: center;
        width: 326px;
        flex: 0 0 326px;
        padding-right: 24px;
        @include d {
            flex: 0 0 244px;
            width: 244px; }
        @include m {
            width: 100%;
            padding-right: 0; } }
    &__wrap {
        width: calc(100% - 326px);
        flex: 0 0 calc(100% - 326px);
        @include d {
            width: calc(100% - 244px);
            flex: 0 0 calc(100% - 244px); }
        @include m {
            display: none; } }

    &__avatar {
        flex-shrink: 0;
        width: 88px;
        height: 88px;
        margin-right: 20px;
        @include m {
            width: 72px;
            height: 72px;
            margin-right: 16px; }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%; } }
    &__title {
        @include body-bold-2; }
    &__counter {
        @include caption-2;
        color: $neutrals4; }
    &__button {
        height: 32px;
        margin-top: 12px;
        padding: 0 12px;
        @include m {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            margin-top: 0; } }
    &__gallery {
        display: flex;
        margin: 0 -4px;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none; } }
    &__preview {
        flex-shrink: 0;
        width: 112px;
        margin: 0 4px;
        img {
            width: 100%;
            border-radius: 12px; } }
    .loader {
        margin: 48px auto 10px; } }
