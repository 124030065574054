.field {
    &__label {
        margin-bottom: 12px;
        @include hairline-2;
        color: $neutrals5; }
    &__wrap {
        position: relative; }
    &__input,
    &__textarea {
        width: 100%;
        border-radius: 12px;
        border: 2px solid $neutrals6;
        background: none;
        @include poppins;
        @include caption-bold-1;
        color: $neutrals2;
        transition: border-color .2s;
        @include dark {
            border-color: $neutrals3;
            color: $neutrals8; }
        @include placeholder {
            color: $neutrals4; }
        &:focus {
            border-color: $neutrals4;
            @include dark {
                border-color: $neutrals4; } } }
    &__input {
        height: 48px;
        padding: 0 14px; }
    &__textarea {
        height: 96px;
        padding: 10px 14px;
        resize: none; }
    &__currency {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        pointer-events: none; }
    &_textarea {
        font-size: 0; } }
