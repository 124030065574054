.options {
    &__list {
        display: flex;
        padding: 8px;
        border-radius: 32px;
        background: $neutrals8;
        @include dark {
            background: $neutrals2; } }
    &__button {
        .icon {
            width: 24px;
            height: 24px; }
        &_favorite {
            .icon {
                fill: $neutrals4;
                transition: fill .2s;
                transition: opacity .2s; }
            &:before {
                content: "";
                position: absolute;
                top: 51%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 22px;
                height: 22px;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
                opacity: 0;
                transition: opacity .2s; }
            &:hover {
                .icon {
                    fill: $neutrals2; } }
            &.active {
                .icon {
                    opacity: 0; }
                &:before {
                    opacity: 1; } }
            &:hover {
                .icon {
                    fill: $neutrals8; } } }
        &_share {
            &.active {
                background: $blue;
                box-shadow: inset 0 0 0 2px $blue;
                @include dark {
                    background: $blue;
                    box-shadow: inset 0 0 0 2px $blue; }
                .icon {
                    fill: $neutrals8; } } }
        &:not(:last-child) {
            margin: 0 24px 0 0; } }
    &__box {
        position: absolute;
        left: 50%;
        bottom: calc(100% + 8px);
        z-index: 2;
        width: 220px;
        transform: translateX(-50%);
        padding: 32px 16px;
        background: $neutrals8;
        border: 1px solid $neutrals6;
        box-shadow: 0px 32px 32px -8px rgba(31, 47, 70, 0.12);
        border-radius: 16px;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        @include dark {
            background: $neutrals2;
            border-color: $neutrals3; }
        &.active {
            visibility: visible;
            opacity: 1; } }
    &__stage {
        margin-bottom: 24px;
        font-weight: 500; }
    &__share {
        display: flex;
        justify-content: center; }
    &__direction {
        span {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: $neutrals6;
            cursor: pointer;
            transition: all .2s;
            .icon {
                width: 20px;
                height: 20px;
                transition: fill .2s; }
            &:hover {
                background: $blue;
                .icon {
                    fill: $neutrals8; } }
            @include dark {
                background: $neutrals3;
                .icon {
                    fill: $neutrals8; }
                &:hover {
                    background: $blue;
                    .icon {
                        fill: $neutrals8; } } } }
        &:not(:last-child) {
            margin-right: 24px; } } }
