.details {
    &__center {
        max-width: 896px; }
    &__top {
        margin-bottom: 64px;
        @include m {
            margin-bottom: 32px;
            padding-bottom: 16px;
            border-bottom: 1px solid $neutrals6;
            @include dark {
                border-color: $neutrals3; } } }
    &__title {
        margin-bottom: 16px; }
    &__info {
        color: $neutrals4;
        strong {
            font-weight: 500;
            color: $neutrals2;
            @include dark {
                color: $neutrals8; } } }
    &__row {
        display: flex;
        margin: 0 -16px;
        @include d {
            display: block;
            margin: 0; } }
    &__col {
        flex: 0 0 calc(50% - 32px);
        width: calc(50% - 32px);
        margin: 0 16px;
        @include d {
            width: 100%;
            margin: 0; }
        &:not(:last-child) {
            @include d {
                margin-bottom: 64px; }
            @include m {
                margin-bottom: 32px;
                padding-bottom: 32px;
                border-bottom: 1px solid $neutrals6;
                @include dark {
                    border-color: $neutrals3; } } } }
    &__user {
        display: flex;
        @include d {
            max-width: 416px; } }
    &__avatar {
        flex-shrink: 0;
        width: 128px;
        height: 128px;
        margin-right: 32px;
        @include m {
            width: 64px;
            height: 64px;
            margin-right: 16px; }
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover; } }
    &__wrap {
        flex-grow: 1; }
    &__stage {
        margin-bottom: 8px;
        @include body-bold-2; }
    &__text {
        margin-bottom: 16px;
        @include caption-2;
        color: $neutrals4; }
    &__file {
        display: inline-block;
        position: relative;
        overflow: hidden; }
    &__input {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 140px;
        opacity: 0; }
    &__list {
        margin-bottom: 40px;
        @include d {
            display: flex;
            margin: 0 -20px 64px; }
        @include m {
            display: block;
            margin: 0 0 32px; } }
    &__item {
        @include d {
            flex: 0 0 calc(50% - 40px);
            width: calc(50% - 40px);
            margin: 0 20px; }
        @include m {
            width: 100%;
            margin: 0; }
        & > .details__button {
            margin-top: 32px;
            .icon {
                width: 16px;
                height: 16px; } }
        &:not(:last-child) {
            margin-bottom: 40px;
            @include d {
                margin-bottom: 0; }
            @include m {
                margin-bottom: 32px; } } }
    &__category {
        margin-bottom: 32px;
        @include body-bold-2; }
    &__fieldset {
        & > .field {
            &:not(:last-child) {
                margin-bottom: 32px; } } }
    &__box {
        position: relative;
        .details__button {
            position: absolute;
            right: 12px;
            bottom: 8px;
            height: 32px;
            padding: 0 10px; } }
    &__note {
        color: $neutrals4; }
    &__btns {
        display: flex;
        margin-top: 40px;
        padding-top: 40px;
        border-top: 1px solid $neutrals6;
        @include m {
            display: block;
            text-align: center; }
        @include dark {
            border-color: $neutrals3; }
        .details__button {
            @include m {
                width: 100%; }
            &:not(:last-child) {
                margin-right: 32px;
                @include m {
                    margin: 0 0 32px; } } } }
    &__clear {
        display: inline-flex;
        align-items: center;
        @include button-1;
        color: $neutrals4;
        transition: color .2s;
        .icon {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            fill: $neutrals4;
            transition: fill .2s; }
        &:hover {
            color: $blue;
            .icon {
                fill: $blue; } } } }
