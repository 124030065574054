.collections {
    &__wrapper {
        position: relative; }
    &__title {
        margin-bottom: 80px;
        @include d {
            margin-bottom: 64px; }
        @include m {
            margin-bottom: 32px;
            text-align: center; } }
    &__inner {
        margin: 0 -16px; }
    &__gallery {
        display: flex;
        flex-wrap: wrap;
        margin: -8px -4px 16px; }
    &__preview {
        flex: 0 0 calc(33.333% - 8px);
        width: calc(33.333% - 8px);
        margin: 8px 4px 0;
        &:first-child {
            flex: 0 0 calc(100% - 8px);
            width: calc(100% - 8px); }
        img {
            width: 100%;
            border-radius: 8px; } }
    &__subtitle {
        margin-bottom: 8px;
        @include body-bold-1;
        color: $neutrals2;
        transition: color .2s;
        @include dark {
            color: $neutrals8; } }
    &__item:hover &__subtitle {
        color: $blue; }
    &__line {
        display: flex;
        align-items: center; }
    &__user {
        display: flex;
        align-items: center;
        margin-right: auto;
        color: $neutrals3;
        @include dark {
            color: $neutrals6; }
        span {
            font-weight: 500; } }
    &__avatar {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        margin-right: 12px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%; } }
    &__counter {
        flex-shrink: 0; }
    &__slider {
        position: static;
        visibility: hidden;
        @include m {
            padding-bottom: 72px; }
        &.slick-initialized {
            visibility: visible; } }
    .slick-list {
        overflow: visible; }
    .slick-slide {
        padding: 0 16px;
        opacity: 0;
        transition: opacity .4s;
        &.slick-active {
            opacity: 1; } }
    .slick-arrow {
        top: 4px;
        @include m {
            top: auto;
            bottom: 0; } }
    .slick-prev {
        right: 48px;
        @include m {
            right: auto;
            left: calc(50% - 44px); } }
    .slick-next {
        right: 0;
        @include m {
            right: calc(50% - 44px); } } }
