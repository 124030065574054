.preview {
    &__inner {
        padding: 48px;
        background: $neutrals8;
        box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.12);
        border-radius: 16px;
        @include d {
            padding: 24px; }
        @include t {
            position: relative;
            width: 352px;
            margin: auto; }
        @include dark {
            background: $neutrals1;
            box-shadow: inset 0 0 0 1px $neutrals3; } }
    &__close {
        display: none;
        position: absolute;
        top: 20px;
        right: 24px;
        width: 40px;
        height: 40px;
        background: $neutrals8;
        border: 2px solid $neutrals6;
        border-radius: 50%;
        font-size: 0;
        @include t {
            display: inline-block; }
        .icon {
            width: 14px;
            height: 14px;
            fill: $neutrals2;
            transition: transform .2s; }
        @include dark {
            border-color: $neutrals3;
            background: $neutrals1;
            .icon {
                fill: $neutrals8; } } }
    &__info {
        margin-bottom: 24px;
        @include body-bold-1; }
    &__clear {
        display: inline-flex;
        align-items: center;
        margin-top: 24px;
        @include button-1;
        color: $neutrals4;
        transition: color .2s;
        .icon {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            fill: $neutrals4;
            transition: fill .2s; }
        &:hover {
            color: $blue;
            .icon {
                fill: $blue; } } } }
