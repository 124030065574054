.main {
    &__head {
        max-width: 600px;
        margin: 0 auto 128px;
        text-align: center;
        @include x {
            margin-bottom: 112px; }
        @include m {
            margin-bottom: 64px; } }
    &__stage {
        margin-bottom: 8px;
        @include hairline-2;
        color: $neutrals4; }
    &__title {
        margin-bottom: 24px;
        @include m {
            font-size: 32px; } }
    &__wrapper {
        position: relative;
        margin: 0 -12px; }
    &__row {
        display: flex;
        align-items: flex-start;
        @include t {
            display: block; } }
    .player {
        flex-grow: 1;
        @include t {
            max-width: 768px;
            margin: 0 auto; } }
    &__details {
        flex-shrink: 0;
        width: 352px;
        margin-left: 128px;
        padding-bottom: 80px;
        @include x {
            margin-left: 64px; }
        @include d {
            width: 304px;
            margin-left: 32px;
            padding-bottom: 64px; }
        @include t {
            width: 100%;
            margin: 48px 0 0;
            padding-bottom: 80px; }
        @include m {
            margin-top: 32px; } }
    &__subtitle {
        margin-bottom: 20px;
        @include d {
            font-size: 48px; } }
    &__line {
        display: flex;
        margin: 0 -16px 40px;
        @include d {
            margin-bottom: 24px; } }
    &__item {
        display: flex;
        align-items: center;
        flex: 0 0 calc(50% - 32px);
        width: calc(50% - 32px);
        margin: 0 16px; }
    &__avatar,
    &__icon {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        margin-right: 8px; }
    &__avatar {
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover; } }
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $green;
        border-radius: 50%;
        .icon {
            width: 24px;
            height: 24px;
            fill: $neutrals8; } }
    &__description {
        flex-grow: 1; }
    &__category {
        @include caption-2;
        color: $neutrals4; }
    &__text {
        font-weight: 500; }
    &__wrap {
        margin-bottom: 40px;
        padding: 30px 16px;
        box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
        border: 2px solid $neutrals6;
        border-radius: 24px;
        text-align: center;
        @include d {
            margin-bottom: 32px;
            padding: 24px 16px; }
        @include dark {
            background: $neutrals2;
            border-color: $neutrals2; } }
    &__info {
        @include body-bold-2; }
    &__currency {
        @include dm-sans;
        font-size: 48px;
        line-height: (56/48);
        letter-spacing: -.02em; }
    &__price {
        margin-bottom: 24px;
        @include body-bold-1;
        color: $neutrals4; }
    &__timer {
        display: flex;
        justify-content: center;
        margin-top: 8px; }
    &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        width: 64px;
        &:not(:last-child) {
            margin-right: 20px; } }
    &__number {
        @include dm-sans;
        font-size: 32px;
        line-height: (40/32);
        letter-spacing: -.01em; }
    &__time {
        @include body-bold-2;
        color: $neutrals4; }
    &__btns {
        display: flex;
        flex-direction: column;
        .main__button {
            width: 100%;
            &:not(:last-child) {
                margin-bottom: 8px; } } }
    &__slider {
        position: static;
        visibility: hidden;
        &.slick-initialized {
            visibility: visible; } }
    .slick-list {
        overflow: visible; }
    .slick-slide {
        padding: 0 12px;
        opacity: 0;
        transition: opacity .4s;
        &.slick-active {
            opacity: 1; } }
    .slick-arrow {
        bottom: 8px;
        background: $neutrals8;
        @include dark {
            background: transparent; } }
    .slick-prev {
        right: 324px;
        @include d {
            right: 276px; }
        @include t {
            right: auto;
            left: calc(50% - 56px); }
        @include m {
            left: calc(50% - 44px); } }
    .slick-next {
        right: 276px;
        @include d {
            right: 228px; }
        @include t {
            right: calc(50% - 56px); }
        @include m {
            right: calc(50% - 44px); } } }
