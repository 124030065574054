.footer {
    border-top: 1px solid $neutrals6;
    @include dark {
        border-color: $neutrals3; }
    &__row {
        display: flex;
        padding: 80px 0 48px;
        border-bottom: 1px solid $neutrals6;
        @include t {
            display: block; }
        @include m {
            padding: 64px 0 48px; }
        @include dark {
            border-color: $neutrals3; } }
    &__col {
        &:first-child {
            flex-grow: 1;
            padding-right: 32px;
            @include t {
                position: relative;
                margin-bottom: 32px;
                padding: 0; } }
        &:nth-child(2) {
            display: flex;
            flex-shrink: 0;
            width: 352px;
            @include d {
                width: 276px; }
            @include t {
                width: auto;
                margin-bottom: 32px; }
            @include m {
                display: block;
                border-width: 1px 0;
                border-style: solid;
                border-color: $neutrals6;
                @include dark {
                    border-color: $neutrals3; } } }
        &:nth-child(3) {
            flex-shrink: 0;
            width: 384px;
            padding-left: 32px;
            @include d {
                width: 304px; }
            @include t {
                width: 100%;
                padding: 0; } } }
    &__logo {
        display: inline-block;
        margin-bottom: 32px;
        img {
            width: 128px; } }
    &__info {
        max-width: 256px;
        @include body-1;
        @include d {
            font-size: 16px; } }
    &__theme {
        display: flex;
        align-items: center;
        margin-top: 24px;
        @include t {
            position: absolute;
            top: 5px;
            right: 0;
            margin: 0; } }
    &__details {
        margin-right: 16px;
        @include caption-2;
        @include m {
            margin-right: 10px; } }
    &__group {
        flex: 0 0 50%;
        &:first-child {
            padding-right: 16px;
            @include m {
                padding-right: 0; } }
        &:nth-child(2) {
            padding-left: 16px;
            @include m {
                padding-left: 0; } }
        &:not(:last-child) {
            @include m {
                border-bottom: 1px solid $neutrals6;
                @include dark {
                    border-color: $neutrals3; } } } }
    &__head {
        margin-bottom: 40px;
        @include body-bold-2;
        @include t {
            margin-bottom: 32px; }
        @include m {
            position: relative;
            margin-bottom: 0;
            padding: 32px 32px 32px 0;
            @include hairline-2; }
        .icon {
            display: none;
            @include m {
                display: inline-block;
                position: absolute;
                top: 50%;
                right: 10px;
                width: 10px;
                height: 10px;
                transform: translateY(-50%);
                fill: $neutrals4;
                transition: transform .2s; } }
        &.active {
            .icon {
                transform: translateY(-50%) rotate(180deg); } } }
    &__body {
        @include rmin(768) {
            display: flex !important; }
        @include m {
            display: none;
            padding-bottom: 32px; } }
    &__menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
    &__link {
        @include button-2;
        color: $neutrals4;
        transition: color .2s;
        &:hover {
            color: $blue; }
        &:not(:last-child) {
            margin-bottom: 24px; } }
    &__category {
        margin-bottom: 40px;
        @include body-bold-2; }
    &__text {
        margin-bottom: 24px; }
    &__foot {
        display: flex;
        justify-content: space-between;
        padding: 32px 0;
        @include caption-2;
        @include m {
            display: block;
            padding: 24px 0 32px; } }
    &__copyright {
        color: $neutrals4;
        @include m {
            text-align: center; } }
    &__note {
        @include m {
            display: none; }
        a {
            margin-left: 16px;
            font-weight: 600;
            color: $blue;
            transition: color .2s;
            &:hover {
                color: darken($blue, 10); } } } }
