.control {
    padding: 24px 0;
    border-bottom: 1px solid $neutrals6;
    @include d {
        padding: 16px 0; }
    @include m {
        padding: 24px 0; }
    @include dark {
        border-color: $neutrals3; }
    &__center {
        display: flex;
        align-items: center; }
    &__button {
        margin-right: auto;
        .icon {
            width: 10px;
            height: 10px; } } }
