.hot {
    &__wrapper {
        position: relative; }
    &__title {
        margin-bottom: 64px;
        @include m {
            margin-bottom: 48px; } }
    &__inner {
        margin: 0 -16px;
        @include m {
            margin: 0 -8px; } }
    .card {
        width: 100%;
        height: 100%; }
    &__slider {
        position: static;
        visibility: hidden;
        &.slick-initialized {
            visibility: visible; } }
    .slick-list {
        overflow: visible;
        @include s {
            margin: 0 -215px 0 0; } }
    .slick-track {
        display: flex; }
    .slick-slide {
        display: flex;
        height: auto;
        margin: 0 16px;
        opacity: 0;
        transition: opacity .4s;
        @include m {
            margin: 0 8px; }
        &.slick-active {
            opacity: 1; } }
    .slick-arrow {
        top: 4px; }
    .slick-prev {
        right: 48px; }
    .slick-next {
        right: 0; } }
