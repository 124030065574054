.discover {
    &__title {
        margin-bottom: 64px;
        @include m {
            margin-bottom: 32px; } }
    &__top {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        @include m {
            display: block; }
        .select {
            width: 180px;
            @include t {
                width: 260px; }
            @include m {
                width: 100%;
                margin-bottom: 12px; } } }
    &__nav {
        position: absolute;
        top: 10px;
        left: 50%;
        display: flex;
        justify-content: center;
        transform: translateX(-50%);
        @include t {
            display: none; } }
    &__link {
        margin: 0 6px;
        padding: 6px 12px;
        border-radius: 14px;
        background: none;
        @include button-2;
        color: $neutrals4;
        transition: all .2s;
        &:hover {
            color: $neutrals3;
            @include dark {
                color: $neutrals6; } }
        &.active {
            background: $neutrals3;
            color: $neutrals8;
            @include dark {
                background: $neutrals8;
                color: $neutrals2; } }
        &:nth-child(n+5) {
            @include d {
                display: none; } } }
    &__filter {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0 0 24px;
        flex-shrink: 0;
        border-radius: 24px;
        background: $blue;
        @include dm-sans;
        font-size: 16px;
        line-height: 48px;
        color: $neutrals8;
        transition: background .2s, box-shadow .2s;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        @include m {
            width: 100%; }
        &.active {
            .icon {
                &-filter {
                    display: none; }
                &-close {
                    display: inline-block; } } } }
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 52px;
        padding-right: 10px;
        background: none;
        font-size: 0;
        .icon {
            fill: $neutrals8;
            transition: transform .2s;
            &-filter {
                width: 18px;
                height: 18px; }
            &-close {
                display: none;
                width: 10px;
                height: 10px; } } }
    &__filters {
        display: none;
        padding: 32px 0;
        border-top: 1px solid $neutrals6;
        @include dark {
            border-color: $neutrals3; } }
    &__sorting {
        display: flex;
        flex-wrap: wrap;
        margin: -32px -16px 0;
        @include d {
            margin: -24px -8px 0; }
        @include m {
            display: block;
            margin: 0; } }
    &__cell {
        flex: 0 0 calc(25% - 32px);
        max-width: calc(25% - 32px);
        margin: 32px 16px 0;
        @include d {
            flex: 0 0 calc(25% - 16px);
            max-width: calc(25% - 16px);
            margin: 24px 8px 0; }
        @include t {
            flex: 0 0 calc(50% - 32px);
            max-width: calc(50% - 32px);
            margin: 32px 16px 0; }
        @include m {
            max-width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 20px; } } }
    &__list {
        @include m {
            margin: 0 -8px; } }
    &__slider {
        display: flex;
        flex-wrap: wrap;
        margin: -32px -16px 0;
        @include m {
            display: block;
            margin: 0; } }
    .card {
        flex: 0 0 calc(25% - 32px);
        max-width: calc(25% - 32px);
        margin: 32px 16px 0;
        @include d {
            flex: 0 0 calc(33.333% - 32px);
            max-width: calc(33.333% - 32px); }
        @include t {
            flex: 0 0 calc(50% - 32px);
            max-width: calc(50% - 32px); }
        @include m {
            max-width: 100%;
            margin: 0; } }
    &__btns {
        margin-top: 32px;
        text-align: center;
        @include m {
            display: none; } }
    .slick-list {
        overflow: visible;
        padding-bottom: 72px; }
    .slick-slide {
        padding: 0 8px; }
    .slick-arrow {
        bottom: 0; }
    .slick-prev {
        left: calc(50% - 44px); }
    .slick-next {
        right: calc(50% - 44px); } }
