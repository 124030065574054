.activity {
    &__top {
        position: relative;
        z-index: 3;
        display: flex;
        align-items: center;
        width: calc(100% - 352px);
        padding-right: 128px;
        @include x {
            padding-right: 64px; }
        @include d {
            width: calc(100% - 304px);
            padding-right: 32px; }
        @include t {
            width: 100%;
            padding: 0; } }
    &__row > &__button {
        @include m {
            width: 100%;
            margin-top: 16px; } }
    &__title {
        margin-right: auto; }
    &__toggle {
        position: relative;
        margin-left: 24px;
        .icon {
            &-filter {
                width: 24px;
                height: 24px; }
            &-close {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 14px;
                height: 14px;
                transform: translate(-50%,-50%);
                opacity: 0; } }
        &.active {
            background: $blue;
            box-shadow: inset 0 0 0 2px $blue;
            @include dark {
                background: $blue; }
            .icon {
                fill: $neutrals8;
                &-filter {
                    opacity: 0; }
                &-close {
                    opacity: 1; } } } }
    &__row {
        display: flex;
        align-items: flex-start;
        margin-top: -56px;
        @include t {
            flex-direction: column-reverse;
            margin-top: 0; } }
    &__wrapper {
        flex-grow: 1;
        padding-right: 128px;
        padding-top: 120px;
        @include x {
            padding-right: 64px; }
        @include d {
            padding-right: 32px; }
        @include t {
            width: 100%;
            padding: 0; } }
    &__filters {
        flex-shrink: 0;
        width: 352px;
        padding: 48px;
        border-radius: 24px;
        box-shadow: inset 0 0 0 1px $neutrals6, 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
        @include d {
            width: 304px;
            padding: 32px; }
        @include t {
            display: none;
            width: 100%; }
        @include dark {
            box-shadow: inset 0 0 0 1px $neutrals3, 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
            background: $neutrals2; }
        &.visible {
            @include t {
                display: block;
                margin-top: 32px; }
            @include m {
                margin-top: 16px; } } }
    &__nav {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -6px 32px;
        @include t {
            margin-top: 40px; }
        @include m {
            margin-top: 32px; } }
    &__link {
        margin: 0 6px;
        padding: 6px 12px;
        border-radius: 14px;
        background: none;
        @include button-2;
        color: $neutrals4;
        transition: all .2s;
        &:hover {
            color: $neutrals3;
            @include dark {
                color: $neutrals6; } }
        &.active {
            background: $neutrals3;
            color: $neutrals8; } }
    &__item {
        position: relative;
        display: flex;
        align-items: center;
        padding: 16px 64px 16px 16px;
        border-radius: 20px;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        transition: background .2s;
        @include m {
            padding-right: 32px; }
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 24px;
            width: 12px;
            height: 12px;
            transform: translateY(-50%);
            transition: all .2s;
            @include m {
                right: 12px; } }
        &:before {
            border-radius: 50%;
            background: $blue; }
        &:after {
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.793.793a1 1 0 0 0 0 1.414L3.586 5 .793 7.793a1 1 0 0 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50% / 6px auto;
            opacity: 0; }
        &:hover {
            background: $neutrals7;
            @include dark {
                background: $neutrals2; }
            &:before {
                transform: translateY(-50%) scale(0); }
            &:after {
                opacity: 1; } }
        &:not(:last-child) {
            margin-bottom: 16px; } }
    &__preview {
        position: relative;
        flex-shrink: 0;
        width: 96px;
        height: 96px;
        margin-right: 24px;
        @include m {
            width: 64px;
            height: 64px;
            margin-right: 16px; }
        & > img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover; } }
    &__icon {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        @include m {
            width: 24px;
            height: 24px; }
        @include dark {
            border: 1px solid $neutrals3; }
        img {
            max-width: 16px;
            @include m {
                max-width: 12px; } } }
    &__details {
        flex-grow: 1; }
    &__subtitle {
        @include body-bold-1;
        color: $neutrals2;
        @include m {
            margin-bottom: 3px;
            font-size: 16px; }
        @include dark {
            color: $neutrals8; } }
    &__description {
        @include body-2;
        color: $neutrals3;
        @include m {
            font-size: 12px; }
        @include dark {
            color: $neutrals6; } }
    &__date {
        @include caption-bold-2;
        color: $neutrals4; }
    .loader {
        margin: 32px auto 0; }
    &__info {
        margin-bottom: 32px;
        @include body-bold-1; }
    &__group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 32px;
        padding-bottom: 32px;
        border-bottom: 1px solid $neutrals6;
        @include dark {
            border-color: $neutrals3; } }
    .checkbox {
        &:not(:last-child) {
            margin-bottom: 24px; } }
    &__btns {
        display: flex;
        margin: 0 -6px;
        .activity__button {
            width: calc(50% - 12px);
            flex: 0 0 calc(50% - 12px);
            height: 32px;
            margin: 0 6px;
            padding: 0 8px;
            @include dark {
                box-shadow: inset 0 0 0 2px $neutrals4;
                &:hover {
                    box-shadow: inset 0 0 0 2px $blue; } } } } }
