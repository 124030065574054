.loader-circle,
.loader-circle:after {
    border-radius: 50%; }

.loader-circle {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 5px solid $neutrals5;
    border-right: 5px solid $neutrals5;
    border-bottom: 5px solid $neutrals5;
    border-left: 5px solid #3772ff;
    transform: translateZ(0);
    animation: loader-circle 1.1s infinite linear;
    @include dark {
        border-top: 5px solid $neutrals3;
        border-right: 5px solid $neutrals3;
        border-bottom: 5px solid $neutrals3; } }

@keyframes loader-circle {
    0% {
        transform: rotate(0deg); }
    100% {
        transform: rotate(360deg); } }
