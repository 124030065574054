.accept {
    &__line {
        display: flex;
        align-items: center;
        margin-top: 72px;
        margin-bottom: 32px;
        @include body-2;
        strong {
            white-space: nowrap;
            font-weight: 500; } }
    &__icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        margin-right: 16px;
        background: $green;
        border: 2px solid $green;
        border-radius: 50%;
        transition: all .2s;
        .icon {
            width: 24px;
            height: 24px;
            fill: $neutrals8;
            transition: fill .2s; }
        .loader {
            position: absolute;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
            background: $neutrals8;
            @include dark {
                background: $neutrals1; } } }
    &__subtitle {
        margin-bottom: 16px;
        @include body-bold-1; }
    &__row {
        display: flex;
        padding-top: 12px;
        &:first-child {
            border-top: 1px solid $neutrals6;
            @include dark {
                border-color: $neutrals3; } } }
    &__col {
        &:first-child {
            margin-right: auto;
            padding-right: 16px;
            color: $neutrals4; }
        &:nth-child(2) {
            font-weight: 500; } } }
