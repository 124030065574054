// selectors
@mixin nl {
  &:not(:last-child) {
    @content; } }
@mixin nf {
  &:not(:first-child) {
    @content; } }

// width
@mixin r($width) {
  @media only screen and (max-width: $width+ "px") {
    @content; } }

@mixin rmin($width) {
  @media only screen and (min-width: $width+ "px") {
    @content; } }

// height
@mixin rh($height) {
  @media only screen and (max-height: $height + "px") {
    @content; } }

@mixin rhmin($height) {
  @media only screen and (min-height: $height + "px") {
    @content; } }

@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

// retina, also available as +hdpi
@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
    @content; } }

// font-family
@mixin font($alias, $name) {
  @font-face {
    font-family: $alias;
    src: url("fonts/" + $name + ".woff2") format("woff2"), url("fonts/" + $name + ".woff") format("woff");
    font-weight: normal;
    font-style: normal; } }
// to use:
//+font(black, cytiapro-black-webfont)

//triangle
@mixin arr($width, $height, $bg, $direction) {
  width: 0px;
  height: 0px;
  border-style: solid;
  @if $direction == t {
    border-width: 0 $width / 2 + px $height + px $width / 2 + px;
    border-color: transparent transparent $bg transparent; }
  @if $direction == r {
    border-width: $height / 2 + px 0 $height / 2 + px $width + px;
    border-color: transparent transparent transparent $bg; }
  @if $direction == b {
    border-width: $height + px $width / 2 + px 0 $width / 2 + px;
    border-color: $bg transparent transparent transparent; }
  @if $direction == l {
    border-width: $height / 2 + px $width + px $height / 2 + px 0;
    border-color: transparent $bg transparent transparent; }
  @if $direction == tl {
    border-width: $height + px $width + px 0 0;
    border-color: $bg transparent transparent transparent; }
  @if $direction == tr {
    border-width: 0 $width + px $height + px 0;
    border-color: transparent $bg transparent transparent; }
  @if $direction == br {
    border-width: 0 0 $height + px $width + px;
    border-color: transparent transparent $bg transparent; }
  @if $direction == bl {
    border-width: $width + px 0 0 $height + px;
    border-color: transparent transparent transparent $bg; } }
// example
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)

// hack for fancybox sass
@function max($numbers...) {
  @return m#{a}x(#{$numbers}); }

@function min($numbers...) {
  @return m#{i}n(#{$numbers}); }

// placeholder
@mixin placeholder {
  &::placeholder {
    @content; } }

// theme
@mixin dark {
  body.dark & {
    @content; } }
